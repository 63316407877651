import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Pricing = ({ location }) => (
  <Layout>
    <SEO title="Cennik" />
    <div class="container">
      <div class="main">
        <ol>
          <li><Link to="/">Strona główna</Link> / <Link to="/pricing">Cennik</Link></li>
        </ol>
        <h1>Cennik:</h1>
        <hr></hr>
        <div className="col-md-4">
          <a href="https://partnerzy.fototransfer.pl/assets/pdf/KATALOG_FOTOTRANSFER_detal_m.pdf">
            <div className="box__download">
              <h4><i className="fas fa-file-pdf"></i> Katalog z sugerowanymi cenami detalicznymi</h4>
            </div>
          </a>
        </div>
      </div>
    </div>
    {/* <Link to="/">Go back to the homepage</Link> */}
  </Layout>
)

export default Pricing
